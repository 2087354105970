import {STRING_REGEXP} from '../services/constants/RegExpConstants';

export const countryCodeFormatter = (value) => {
  let code = value.replace(/[^0-9]/g, '');
  if (code[0] && code[0] !== '+') {
    code = `+${code}`;
  }
  return code;
};

export const phoneNumberFormatter = (value) => {
  return value.replace(/[^0-9]/g, '');
};

export const numbersWithDecimalsFormatter = (value) => {
  return value.replace(/[^0-9.]/g, '');
};
export const twoDecimalsFormatter = (
  value,
  prevValue,
  maxValue = 9999.99,
  canBeZero = false
) => {
  if (value === '') {
    return '';
  }

  const formedValue = value.replace(',', '.');
  let resTax = parseFloat(formedValue);

  if (value.includes('-')) {
    resTax = prevValue;
  }

  if (value.length && value.split('')[0] === '.') {
    resTax = prevValue;
  }

  if (value.indexOf('.') !== value.lastIndexOf('.')) {
    return prevValue;
  }

  if (!resTax && (!canBeZero || resTax !== 0)) {
    return '';
  }

  if (resTax > maxValue) {
    resTax = prevValue;
  }

  if (formedValue.slice(-1) === '.') {
    resTax = `${resTax}.`;
  }

  if (String(resTax).split('.')[1]?.length > 2) {
    return prevValue;
  }

  if (formedValue.slice(-2) === '.0') {
    return formedValue;
  }

  return resTax;
};

export const phoneFormatForView = (value) => {
  if (typeof value !== 'string') {
    return null;
  }

  const rgClear = /[^0-9+]/g;

  const rgFormat = /([\d]{3})([\d]{3})([\d]+)/g;
  const rgFormatMask = '($1) $2-$3';

  const rgFormatWithCountryCode = /([1+]{2})([\d]{3})([\d]{3})([\d]+)/g;
  const rgFormatWithCountryCodeMask = '$1 ($2) $3-$4';

  let phone = value.replace(rgClear, '');

  if (phone[0] === '+') {
    phone = phone.replace(rgFormatWithCountryCode, rgFormatWithCountryCodeMask);
  } else {
    phone = phone.replace(rgFormat, rgFormatMask);
  }

  // Look for NON US numbers and refactor to US like format
  if (phone === value) {
    if (phone[0] === '+') {
      phone = `+${phone
        .replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}`;
    } else {
      phone = phone
        .replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
  }
  return phone;
};

export const taxFormatter = (newTax, prevTax = '', maxTax = 100) => {
  if (newTax === '') {
    return '';
  }

  const formedNewTax = newTax.replace(',', '.').replace('%', '');
  let resTax = parseFloat(formedNewTax);

  if (!resTax) {
    return prevTax || '';
  }

  if (formedNewTax.length > 5) {
    return prevTax;
  }

  if (formedNewTax.indexOf('.') !== formedNewTax.lastIndexOf('.')) {
    return prevTax;
  }

  if (resTax > maxTax) {
    return prevTax;
  }

  if (formedNewTax.slice(-1) === '.') {
    resTax = `${resTax}.`;
  }

  if (String(resTax).split('.')[1]?.length > 3) {
    return prevTax;
  }

  if (formedNewTax.slice(-2) === '.0') {
    return formedNewTax;
  }

  return resTax;
};

export const engFieldFormatter = (nextValue, prevValue) => {
  let value = prevValue;
  const nextValueMatch = nextValue?.match(new RegExp(STRING_REGEXP));
  const prevValueMatch = prevValue?.match(new RegExp(STRING_REGEXP));
  if (nextValueMatch?.length) {
    value = nextValue;
  } else if (!nextValueMatch && prevValueMatch) {
    value = nextValue;
  } else if (!nextValueMatch && !prevValueMatch) {
    value = prevValue;
  }
  return value;
};

export const etaFormatter = (value) => {
  let res = value;
  if (value.length > 1 && value[0] === '0') {
    res = res[1];
  }
  res = res.replace(/[^0-9]/g, '');
  if (res > 120) {
    res = res.slice(0, -1);
  }

  return res;
};

export const maxLenFormatter = (value, prevValue, maxLen = 150) => {
  if (value.length > maxLen) {
    return prevValue;
  }

  return value;
};

export const numberWithCommasFormatter = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const integerFormatter = (value) => {
  return Number(value.replace(/[^0-9]/g, ''));
};
