export const refundFaultTypes = {
  0: 'Restaurant',
  1: 'DeliveryCompany',
  2: 'Sauce'
};

export const REFUND_FAULT_TITLE = {
  Restaurant: 'Restaurant',
  DeliveryCompany: 'Delivery company',
  Sauce: 'Sauce'
};

export const REFUND_SOURCE = {
  Sauce: 'Calculator',
  External: 'External',
  Restaurant: 'Merchant refund button'
};

export const RESTAURANT_REFUND_TYPE = {
  Full: 0,
  Partial: 1
};

export const RESTAURANT_REFUND_INITIATOR = {
  Restaurant: 0,
  Customer: 1
};

export const REFUND_OPERATION_TYPE_ORDER = {
  LocationReverse: 'Merchant reverse',
  FranchisorReverse: 'Commission reverse',
  CustomerRefund: 'User refund'
};
export const REFUND_OPERATION_TYPE_ADJUST = {
  LocationReverse: 'Adjustment merchant reverse',
  FranchisorReverse: 'Adjustment commission reverse',
  CustomerRefund: 'User refund'
};

export const REFUND_TYPES = {
  Full: 'Full',
  Partial: 'Partial',
  Subtotal: 'Subtotal',
  FixedAmount: 'Fixed'
};

export const FIXED_AMOUNT_REASON = [
  {value: 0, title: 'Select'},
  {value: 'Tip Adjustment', title: 'Tip Adjustment'},
  {value: 'Order Items Adjustment', title: 'Order Items Adjustment'},
  {
    value: 'Item Refund Calculator Not Supported',
    title: 'Item Refund Calculator Not Supported'
  },
  {
    value: 'Store Requested Compensation',
    title: 'Store Requested Compensation'
  },
  {
    value: 'Protocol Fix for Unlisted Item Refund',
    title: 'Protocol Fix for Unlisted Item Refund'
  },
  {value: 'Refund for No-Price Item', title: 'Refund for No-Price Item'},
  {value: 'Taxes and Fees', title: 'Taxes and Fees'},
  {value: 'Other', title: 'Other (Free Text)'}
];

export default refundFaultTypes;
