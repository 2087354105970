import styled from 'styled-components';

export const BillDottedLine = styled.hr`
  border: none;
  border-top: 1px dashed #929cb9;
  opacity: 0.7;
  color: #fff;
  background-color: #fff;
  height: 1px;
`;
export const OrderDetails = styled.div`
  display: flex;
  align-items: stretch;
  @media (max-width: 599px) {
    display: block;
  }
`;

export const DeliveryService = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  img {
    display: inline-block;
    flex: 0 0 auto;
    width: 44px;
  }
`;

export const DeliveryServiceInfo = styled.div`
  flex: 1 1 auto;
  padding-left: 10px;
`;

export const Amount = styled.div`
  display: flex;
  align-items: center;
`;

export const OrderDetailsLside = styled.div`
  flex: 0 0 auto;
  width: 300px;
  overflow-wrap: break-word;
  @media (max-width: 767px) {
    max-width: 240px;
    width: 100%;
  }
  @media (max-width: 599px) {
    max-width: 100%;
  }
`;

export const OrderDetailsRside = styled.div`
  flex: 1 1 auto;
  max-width: 600px;
  padding-left: 100px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    padding-left: 50px;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 599px) {
    padding: 30px 20px 0 0;
  }
`;
export const OrderDetailsStatusButtons = styled.div`
  margin-bottom: 20px;
  button {
    &:first-child {
      margin-right: 10px;
    }
  }
`;
export const OrderDetailsActions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex: 0 0 auto;
  @media (max-width: 599px) {
    margin-bottom: 20px;
  }
`;
export const OrderDetailsNotes = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .FieldTextareaLg {
    flex: 1 1 auto;
    height: auto;
    max-width: 340px;
    @media (max-width: 599px) {
      min-height: 160px;
    }
  }

  .ButtonPrimary {
    flex: 0 0 auto;
  }
`;
