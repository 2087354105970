import {put, takeEvery} from 'redux-saga/effects';
import {
  getAddressesBADApi,
  postBADOrderApi,
  postBADCheckAddress
} from '../../../helpers/services/api/bookADrive';
import {
  GET_BAD_ORDER_DETAIL_SUCCESS,
  GET_BAD_ORDER_DETAIL,
  POST_BAD_ORDER,
  POST_BAD_ORDER_SUCCESS,
  SET_BAD_ORDER_DETAIL_SUCCESS,
  SET_BAD_ORDER_DETAIL,
  HANDLE_SUCCESS_MODAL,
  HANDLE_FAIL_MODAL,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES,
  CHECK_ADDRESS_SUCCESS,
  CHECK_ADDRESS,
  SET_LOADING_CHECK_ADDRESS
} from './action';
import {BOOK_A_DRIVER_ERROR} from '../../../helpers/constants/bookAdriver';

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function getErrorMessageObj(msg) {
  let result;
  if (msg) {
    const splitMessage = msg.split('- ');
    const replaceMessage = splitMessage[splitMessage?.length - 1]?.replace(
      /'/g,
      '"'
    );
    if (isJson(replaceMessage)) {
      result = JSON.parse(replaceMessage);
    }
  }
  return result;
}

function getErrorCreateQuote(response) {
  let resultObj = null;
  if (response.errors && response.errors[0]?.path) {
    if (response.errors[0]?.path.match('pickup')) {
      switch (response.errors[0]?.path) {
        case '.body.pickup.address.number':
          resultObj = {
            StoreAddress:
              'Your address does not have any street number specified. Please select another address or choose address with street number specified on the Store Settings page!'
          };
          break;
        case '.body.pickup.address.street':
          resultObj = {
            StoreAddress:
              'Your address does not have any street specified. Please select another address or choose address with street specified on the Store Settings page!'
          };
          break;
        case '.body.pickup.address.city':
          resultObj = {
            StoreAddress:
              'Your address does not have any city specified. Please select another address or choose address with city specified on the Store Settings page!'
          };
          break;
        case '.body.pickup.address.state':
          resultObj = {
            StoreAddress:
              'Your address does not have any state specified. Please select another address or choose address with state specified on the Store Settings page!'
          };
          break;
        case '.body.pickup.address.zip':
          resultObj = {
            StoreAddress:
              'Your address does not have any zip code specified. Please select another address or choose address with zip code specified on the Store Settings page!'
          };
          break;
        default:
          break;
      }
    }
  } else if (response.errors[0]?.error) {
    resultObj = {
      DropoffAddress:
        BOOK_A_DRIVER_ERROR[response.errors[0]?.error] ||
        'The dropoff address exceeds the delivery zone'
    };
  } else if (response.errors[0]?.detail) {
    resultObj = {
      DropoffAddress: response.errors[0]?.detail
    };
  } else {
    resultObj = {
      DropoffAddress: 'The dropoff address exceeds the delivery zone'
    };
  }
  return {errors: resultObj};
}

function* createBookADriverOrder({payload}) {
  try {
    const {delivery} = payload;
    const data = yield postBADOrderApi(payload.data);
    if (data) {
      yield put(POST_BAD_ORDER_SUCCESS());
      yield put(
        HANDLE_SUCCESS_MODAL({
          openSuccessModal: true,
          successData: {
            deliveryType: delivery,
            pickup_time: data?.data?.data?.s2e_meta?.pickup_eta
          }
        })
      );
    }
    return true;
  } catch (error) {
    console.log('SAGA ERROR', error);
    const {response} = error;
    if (response) {
      const responseData = response?.data?.errors;
      if (responseData && responseData?.length && responseData[0]?.error) {
        yield put(
          HANDLE_FAIL_MODAL({
            openFailModal: true,
            failData: {message: BOOK_A_DRIVER_ERROR[responseData[0]?.error]}
          })
        );
        // if (responseData[0].error_msg) {
        //       yield put(
        //           HANDLE_FAIL_MODAL({
        //             openFailModal: true,
        //             failData: {
        //               message: responseData[0].error_msg
        //             }
        //           })
        //       );
        //     } else {
        //       yield put(
        //           HANDLE_FAIL_MODAL({
        //             openFailModal: true,
        //             failData: {
        //               message: responseData[0].error
        //             }
        //           })
        //       );
        //     }

        // if (responseData[0]?.msg?.postmates) {
        //   const postmatesError = getErrorMessageObj(
        //     responseData[0]?.msg?.postmates
        //   );
        //   if (postmatesError) {
        //     yield put(
        //       HANDLE_FAIL_MODAL({
        //         openFailModal: true,
        //         failData: {
        //           message:
        //             postmatesError?.metadata?.dropoff_phone_number ||
        //             postmatesError?.message ||
        //             'Please try again later'
        //         }
        //       })
        //     );
        //   } else {
        //     yield put(
        //       HANDLE_FAIL_MODAL({
        //         openFailModal: true,
        //         failData: {message: 'Please try again later'}
        //       })
        //     );
        //   }
        // } else if (
        //   responseData[0]?.msg?.doordash ||
        //   responseData[0]?.msg?.doordash_catering ||
        //   responseData[0]?.msg?.doordash_2 ||
        //   responseData[0]?.msg?.nash
        // ) {
        //   const doordashError = getErrorMessageObj(
        //     responseData[0]?.msg?.doordash ||
        //       responseData[0]?.msg?.doordash_catering ||
        //       responseData[0]?.msg?.doordash_2 ||
        //       responseData[0]?.msg?.nash
        //   );
        //   if (doordashError) {
        //     if (doordashError?.field_errors?.length) {
        //       yield put(
        //         HANDLE_FAIL_MODAL({
        //           openFailModal: true,
        //           failData: {
        //             message:
        //               doordashError?.field_errors[0]?.error ||
        //               'Please try again later'
        //           }
        //         })
        //       );
        //     } else {
        //       yield put(
        //         HANDLE_FAIL_MODAL({
        //           openFailModal: true,
        //           failData: {message: 'Please try again later'}
        //         })
        //       );
        //     }
        //   }
        // } else {
        //   yield put(
        //       HANDLE_FAIL_MODAL({
        //         openFailModal: true,
        //         failData: {message: 'Please try again later'}
        //       })
        //   );
        // }
      } else {
        yield put(
          HANDLE_FAIL_MODAL({
            openFailModal: true,
            failData: {message: 'Please try again later'}
          })
        );
      }
    }
    return false;
  }
}

function* getBookADriverOrderDetail({payload}) {
  try {
    if (payload) {
      yield put(GET_BAD_ORDER_DETAIL_SUCCESS(payload));
    }
    return true;
  } catch (error) {
    console.log('SAGA ERROR', error);
    return false;
  }
}

function* setBookADriverOrderDetail({payload}) {
  try {
    if (payload) {
      yield put(SET_BAD_ORDER_DETAIL_SUCCESS(payload));
    }
    return true;
  } catch (error) {
    console.log('SAGA ERROR', error);
    return false;
  }
}

function* getAddresses({payload}) {
  try {
    const {data} = yield getAddressesBADApi(encodeURIComponent(payload));
    if (data) {
      yield put(GET_ADDRESSES_SUCCESS(data.data[0]));
    }
    return true;
  } catch (error) {
    console.log('SAGA ERROR', error);
    return false;
  }
}

function* checkAddress({payload}) {
  try {
    if (payload) {
      const {data} = yield postBADCheckAddress(payload);
      if (data) {
        yield put(CHECK_ADDRESS_SUCCESS(data.data));
      }
    }
    return true;
  } catch (error) {
    console.log('SAGA ERROR', error);
    yield put(SET_LOADING_CHECK_ADDRESS(false));
    if (error?.response?.data) {
      yield put(
        CHECK_ADDRESS_SUCCESS(getErrorCreateQuote(error?.response?.data))
      );
    }
    return false;
  }
}

function* bookADriverCreateSaga() {
  yield takeEvery(POST_BAD_ORDER, createBookADriverOrder);
  yield takeEvery(GET_BAD_ORDER_DETAIL, getBookADriverOrderDetail);
  yield takeEvery(SET_BAD_ORDER_DETAIL, setBookADriverOrderDetail);
  yield takeEvery(GET_ADDRESSES, getAddresses);
  yield takeEvery(CHECK_ADDRESS, checkAddress);
}

export default bookADriverCreateSaga;
