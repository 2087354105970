import styled from 'styled-components';

export const FlyersSubtitle = styled.div`
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #172751;
  margin: 24px 0 34px 0;
`;

export const FlyersContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 30px;
  margin-top: 20px;
  position: relative;
  box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.08),
    10px 10px 20px rgba(108, 49, 151, 0.06),
    30px 30px 30px rgba(108, 49, 151, 0.04);
  @media (max-width: 1024px) {
    padding: 20px 24px;
  }
  @media (max-width: 767px) {
    padding: 17px 15px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlyersContainerText = styled.div`
  width: 65%;
  font-size: 14px;
  .title {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: left;
    color: #000000;
    margin: 16px 0 10px 0;
    &:first-child {
      margin-top: 0;
    }
    &.sm {
      font-size: 14px;
      margin: 18px 0;
    }
  }
  ul {
    margin-left: 20px;
  }
  a {
    color: #ff2d38;
    text-decoration: none;
    font-weight: bold;
  }
  &.w100 {
    width: 100%;
  }
`;

export const FlyersContainerImage = styled.img`
  display: block;
  width: 30%;
`;
