import React from 'react';
import T from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {useDispatch} from 'react-redux';
import cn from 'classnames';
import DialogTitle from 'components/DialogTitle/styles';
import DialogContainer from 'components/DialogContainer/styles';
import {DialogBody} from 'routes/App/dashboard/styles';
import DialogField from 'components/DialogField/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline} from 'components/buttons';
import DialogActions from 'components/DialogActions/styles';
import {RC_OPEN_RESULT_MODAL} from 'store/refundsCalculator/action';
import {getTitleForRefundCalculator} from 'helpers/functionUtils/refundHelper';

const RequestResultModal = ({openModal, info}) => {
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(RC_OPEN_RESULT_MODAL({isOpen: false, info: {}}));
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'Succeeded':
        return 'Success';
      case 'Failed':
        return 'Fail';
      default:
        return '';
    }
  };

  return (
    <Dialog open={openModal} onClose={onClose} scroll='body'>
      <DialogContainer className='DialogMd'>
        <DialogTitle className='DialogTitleMarginBottom'>
          {info?.title}
        </DialogTitle>
        <DialogBody>
          {!!info?.subtitleBold && (
            <DialogField className='Center bold'>
              {info?.subtitleBold}
            </DialogField>
          )}
          {info?.steps?.length > 0 && (
            <>
              {info?.steps?.map((step) => (
                <>
                  <DialogField className='TextUnderline'>
                    {step?.refundSourceType === 'Order'
                      ? `${step?.refundSourceType} transaction - ${step?.paymentIntentId}`
                      : `Adjustment transaction - ${step?.paymentIntentId}`}
                  </DialogField>
                  {step?.statuses?.map((status, index) => (
                    <DialogField className='RefundCalculatorErrorModal'>
                      {getTitleForRefundCalculator(
                        step?.refundSourceType,
                        status?.refundOperationType
                      )}
                      -{' '}
                      <span
                        className={cn('', {
                          failed: status?.status !== 'Succeeded'
                        })}
                      >
                        {getStatusText(status?.status)}
                      </span>
                    </DialogField>
                  ))}
                </>
              ))}
            </>
          )}

          <DialogActions className='justifyCenter'>
            <DialogAction className='w50'>
              <ButtonOutline onClick={onClose} type='button'>
                <span>Close</span>
              </ButtonOutline>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

RequestResultModal.propTypes = {
  openModal: T.bool.isRequired,
  info: T.object.isRequired
};

export default RequestResultModal;
