import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, useHistory, useLocation} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';
import Headline from '../../../../components/Headline/styles';
import {DashboardHeaderActions, OrderTab, OrderTabs} from './styles';
import {Body2, Caption1} from '../../../../components/typography';
import ButtonOutline from '../../../../components/buttons/ButtonOutline/styles';
import OrdersNotifications from './Modals/OrdersNotifications';
import OrdersSettings from './Modals/OrdersSettings';
import Bill from '../components/Bill';
import ActiveOrders from './ActiveOrders';
import OrdersHistory from './OrdersHistory';
import history from '../../../../history';
import {DashboardHeader} from '../../../../components/DashboardHeader/styles';
import {DashboardInner} from '../../../../components/DashboardInner/styles';
import {DashboardContent} from '../../../../components/DashboardContent/styles';
import IconCar from '../../../../assets/IconCar';
import ButtonPrimaryLink from '../../../../components/buttons/ButtonPrimaryLink/styles';
import RestrictedArea from '../../../../components/layout/RestrictedArea';
import {
  FEATURE_RESTRICTION_IDS,
  getBookADriverRestriction
} from '../../../../helpers/constants/restrictions';
import {ORDER_UNMOUNT} from '../../../../store/orders/orderDetails/action';

const Orders = ({hidden}) => {
  const headerRef = useRef();
  const printRef = useRef();
  const print = useReactToPrint({
    pageStyle: `
       @media print {
       html, body {
       width: 80mm;
        }
       }  
     `,
    content: () => printRef.current
  });
  const {
    location: {pathname}
  } = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const printRecord = useSelector(
    (state) => state.ordersHistoryReducer.printRecord
  );

  const locationName = useSelector((state) => state.user.locationName);

  const useSalesPackage = useSelector(
    (state) => state.user.activeLocation.UseSalesPackage
  );

  const timezone = useSelector((state) => state.user.activeLocation.Timezone);

  const isNetworkOnline = useSelector(
    (state) => state.globalNotificationsReducer.isNetworkOnline
  );

  const activeOrdersCount = useSelector(
    (state) => state.activeOrdersReducer.activeOrdersCount
  );

  const UEConnected = useSelector(
    (state) => state.uberEatsReducer.uberEatsData?.isActivated
  );

  const serviceFeatures = useSelector(
    (state) => state.user?.activeLocation?.AllowedServiceFeatures
  );

  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);

  useEffect(() => {
    if (pathname.indexOf('details') === -1) {
      dispatch(ORDER_UNMOUNT()); // This is needed just like hack cz we need to use this event only on details unmount
    }

    if (pathname === '/orders') {
      history.push('/orders/active');
    }
    window.scrollTo(0, location?.state?.windowPosition || 0);
  }, [pathname]);

  return (
    <DashboardContent
      style={hidden ? {display: 'none'} : null}
      className='DashboardContent'
    >
      <DashboardHeader className='OrderHeader'>
        <Headline>Orders</Headline>
        <DashboardHeaderActions className='OrderActions'>
          <ButtonOutline
            className={cn('ButtonMedium', {
              isDisabled: !isNetworkOnline
            })}
            onClick={() => setShowNotificationsModal(true)}
          >
            Notifications
          </ButtonOutline>
          <ButtonOutline
            className={cn('ButtonMedium', {
              isDisabled: !isNetworkOnline
            })}
            onClick={() => setShowSettingsModal(true)}
          >
            Settings
          </ButtonOutline>
          <RestrictedArea
            restrictions={[]}
            features={[getBookADriverRestriction(serviceFeatures)]}
          >
            <ButtonPrimaryLink
              data-gtm='bookADriverButton'
              to='/book-a-driver/create-order'
              className='ButtonMedium ButtonDriver'
            >
              <IconCar data-gtm='bookADriverButton' />
              <span data-gtm='bookADriverButton'>Book a Driver</span>
            </ButtonPrimaryLink>
          </RestrictedArea>
        </DashboardHeaderActions>
      </DashboardHeader>
      <DashboardInner className='withoutShadow paddingXl' ref={headerRef}>
        <OrderTabs>
          <OrderTab activeClassName='isActive' to='/orders/active'>
            <Caption1 className='Caption'>
              <span style={{color: 'red'}}>{activeOrdersCount} </span>Active
              Orders
            </Caption1>
            <Body2>New, Accepted & Overdue orders</Body2>
          </OrderTab>
          <OrderTab
            className={isNetworkOnline ? null : 'isDisabled'}
            activeClassName='isActive'
            to='/orders/history'
          >
            <Caption1 className='Caption'>Orders History</Caption1>
            <Body2>Completed, Unknown & Cancelled</Body2>
          </OrderTab>
        </OrderTabs>
      </DashboardInner>

      <>
        <Route
          path='/orders/active'
          render={() => (
            <ActiveOrders
              parentRef={headerRef.current}
              print={print}
              UEConnected={UEConnected}
            />
          )}
        />
        {isNetworkOnline && (
          <Route
            path='/orders/history'
            render={() => (
              <OrdersHistory parentRef={headerRef.current} print={print} />
            )}
          />
        )}
        <Redirect from='/orders' to='/orders/active' />
      </>

      <div style={{display: 'none'}}>
        {printRecord.id && (
          <Bill
            ref={printRef}
            order={printRecord}
            locationName={locationName}
            useSalesPackage={useSalesPackage}
            timezone={timezone}
          />
        )}
      </div>

      {showNotificationsModal && (
        <OrdersNotifications onClose={() => setShowNotificationsModal(false)} />
      )}
      {showSettingsModal && (
        <OrdersSettings onClose={() => setShowSettingsModal(false)} />
      )}
    </DashboardContent>
  );
};

Orders.propTypes = {
  hidden: PropTypes.bool.isRequired
};

export default Orders;
