import Dialog from '@material-ui/core/Dialog';
import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import DialogTitle from 'components/DialogTitle/styles';
import DialogContainer from 'components/DialogContainer/styles';
import {
  RC_MAKE_ORDER_REFUND,
  RC_OPEN_CONFIRMATION_MODAL
} from 'store/refundsCalculator/action';
import {DialogBody} from 'routes/App/dashboard/styles';
import DialogField from 'components/DialogField/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';
import DialogActions from 'components/DialogActions/styles';
import {
  RefundResultItem,
  RefundResultItemRside,
  RefundResultItems
} from 'routes/App/Managers/RefundsCalculator/styles';
import Body6 from 'components/typography/Body6';

const ConfirmationRefundModal = ({openModal, refundType}) => {
  const dispatch = useDispatch();

  const {
    endCustomerRefund,
    restaurantReverse,
    refundResponsibility,
    orderData,
    partialRefCheckboxes,
    priceAdjustmentIds
  } = useSelector((state) => state.refundsCalculator);
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    if (orderData.items.length) {
      setCheckedItems(orderData.items.filter((item) => item.checked));
    }
  }, [orderData?.items]);

  const onClose = () => {
    dispatch(RC_OPEN_CONFIRMATION_MODAL(false));
  };

  const makeRefund = () => {
    dispatch(RC_MAKE_ORDER_REFUND(refundType));
  };

  return (
    <Dialog open={openModal} onClose={onClose} scroll='body'>
      <DialogContainer className='DialogSm'>
        <DialogTitle className='DialogTitleMarginBottom'>
          {priceAdjustmentIds?.length > 0 && 'Adjusted order. '}Are you sure you
          want to process the refund for this order?
        </DialogTitle>
        <DialogBody>
          <DialogField>
            {refundType === 0 && 'Full order refund'}{' '}
            {refundType === 2 && 'Subtotal % refund'}{' '}
            {refundType === 3 && 'Fixed amount refund'}{' '}
            {refundType === 1 && (
              <>
                Partial refund for:
                <RefundResultItems className='noBorder'>
                  {checkedItems?.map((orderItem) => (
                    <RefundResultItem
                      modifierLevel={orderItem.path?.length}
                      className={cn({
                        modifier: orderItem.path?.length > 1
                      })}
                    >
                      <Body6 className='list-title minusWidth fw400'>
                        {orderItem.selected > 1 && `${orderItem.selected}X `}
                        {orderItem.name}
                      </Body6>
                    </RefundResultItem>
                  ))}
                </RefundResultItems>
                {priceAdjustmentIds?.length > 0 && (
                  <RefundResultItems className='noBorder'>
                    {priceAdjustmentIds?.map((adjust) => (
                      <RefundResultItem>
                        <Body6 className='list-title minusWidth fw400'>
                          order adjustment for{' '}
                          {
                            orderData?.priceAdjustments?.find(
                              (el) => el.id === adjust
                            )?.reason
                          }
                        </Body6>
                      </RefundResultItem>
                    ))}
                  </RefundResultItems>
                )}
                <RefundResultItems className='noBorder'>
                  {!!orderData.tip && partialRefCheckboxes?.tip && (
                    <RefundResultItem>
                      <Body6 className='list-title minusWidth fw400'>Tip</Body6>
                    </RefundResultItem>
                  )}
                  {!!orderData.deliveryFee &&
                    partialRefCheckboxes?.deliveryFee && (
                      <RefundResultItem>
                        <Body6 className='list-title minusWidth fw400'>
                          Delivery fee
                        </Body6>
                      </RefundResultItem>
                    )}
                </RefundResultItems>
              </>
            )}
            <br />
            <br />
            User refund - <b>${endCustomerRefund}</b>
            <br />
            {restaurantReverse > 0 && (
              <>
                Merchant reverse - <b>${restaurantReverse}</b>
              </>
            )}
          </DialogField>
          {!restaurantReverse && (
            <>
              <DialogField>
                <b>Please note, </b>that no merchant reverse will be made.
              </DialogField>
              <DialogField className='bold'>
                Make sure to process a refund with the delivery company as well.
              </DialogField>
            </>
          )}
          <DialogActions className='w100'>
            <DialogAction className='w50'>
              <ButtonOutline type='button' onClick={onClose}>
                <span>Cancel</span>
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='w50 pR0'>
              <ButtonPrimary onClick={makeRefund} type='button'>
                <span>Yes</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

ConfirmationRefundModal.propTypes = {
  openModal: T.bool.isRequired,
  refundType: T.number.isRequired
};
export default ConfirmationRefundModal;
