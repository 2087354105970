/* eslint-disable */
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {TopLineNav} from '../../../../components/layout';
import LinkBack from '../../../../components/LinkBack/styles';
import DashboardContent from '../../../../components/DashboardContent/styles';
import DashboardInner from '../../../../components/DashboardInner/styles';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {
  Amount,
  OrderDetails,
  OrderDetailsActions,
  OrderDetailsLside,
  OrderDetailsNotes,
  OrderDetailsRside,
  OrderDetailsStatusButtons
} from './styles';
import Subtitle5 from '../../../../components/typography/Subtitle5';
import Subtitle from '../../../../components/typography/Subtitle1';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import {FieldTextarea} from '../../dashboard/styles';
import {useDispatch, useSelector} from 'react-redux';
import {phoneFormatForView} from '../../../../helpers/functionUtils/helpFunctions';
import {Body0, Subtitle1} from '../../../../components/typography';
import sha256 from 'crypto-js/sha256';
import {
  RUN_BAD_ORDER_DETAIL_JOB,
  STOP_BAD_ORDER_DETAIL_JOB,
  SET_BAD_ORDER_DETAIL_NOTES,
  CLEAR_BAD_ORDER,
  SET_BAD_LARGE_ORDER_STATUS
} from '../../../../store/bookADriver/bookADriverDetails/action';
import {usePrevious} from '../../../../hooks/usePrevious';
import {
  BOOK_A_DRIVER,
  BOOK_A_DRIVER_STATUS
} from '../../../../helpers/constants/bookAdriver';
import {getSessionStorageItem} from '../../../../helpers/sessionStorage';
import SESSION_SAUCE_EMPLOYEE_NAME from '../../../../helpers/sessionStorage/constants';

export default function BookADriverDetails() {
  const {order} = useSelector((state) => state.bookADriverOpenDetails);

  const {params} = useRouteMatch();

  const dispatch = useDispatch();

  const history = useHistory();

  const [locationState] = useState(location?.state?.windowPosition);

  const {activeLocation, timezone, locationId} = useSelector(
    (state) => state.user
  );

  const [notes, setNotes] = useState(order?.data?.internal_notes || '');

  function dateToTimezone(date) {
    let newDate = date;
    if (newDate) {
      newDate = moment(newDate)
        .utc()
        .utcOffset(Number(timezone))
        .format('MMMM D, h:mm A');
    }
    return newDate;
  }
  const prevLocation = usePrevious(locationId);

  useEffect(() => {
    if (prevLocation) {
      redirectToPreviousRoute();
    }
  }, [locationId]);

  useEffect(() => {
    if (order?.data?.internal_notes) {
      setNotes(order?.data?.internal_notes);
    }
  }, [order?.data?.internal_notes]);
  useEffect(() => {
    if (params.id) {
      dispatch(STOP_BAD_ORDER_DETAIL_JOB());
      dispatch(RUN_BAD_ORDER_DETAIL_JOB(params.id));
    }
    return () => {
      dispatch(STOP_BAD_ORDER_DETAIL_JOB());
      dispatch(CLEAR_BAD_ORDER());
    };
  }, []);

  const redirectToPreviousRoute = (state = {}) => {
    history.push(
      params.back === 'history'
        ? '/book-a-driver/history'
        : '/book-a-driver/active',
      state
    );
  };

  const checkIfSauceEmployee = () => {
    return getSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME);
  };

  const handleNotes = (e) => {
    setNotes(e.target.value);
  };

  const saveNotes = () => {
    dispatch(
      SET_BAD_ORDER_DETAIL_NOTES({
        id: params.id,
        notes: notes
      })
    );
  };

  const changeStatus = (status) => {
    dispatch(
      SET_BAD_LARGE_ORDER_STATUS({
        id: params.id,
        status
      })
    );
  };

  return (
    <DashboardContent className='DashboardContent'>
      <TopLineNav>
        <LinkBack
          onClick={() => {
            redirectToPreviousRoute({windowPosition: locationState});
          }}
        >
          Back To All Orders
        </LinkBack>
      </TopLineNav>
      <DashboardInner>
        <OrderDetails>
          <OrderDetailsLside>
            <div className='marginBottomXl'>
              {order?.data?.s2e_order_id && (
                <Subtitle5 className='marginBottomXs2 wordWrap'>
                  #{order?.data?.s2e_order_id.substr(-5, 5)}
                </Subtitle5>
              )}
              <Subtitle1 className='marginBottomXs2'>
                {order?.data?.status === BOOK_A_DRIVER_STATUS.SCHEDULED && (
                  <span>Scheduled for delivery</span>
                )}
                {order?.data?.status === BOOK_A_DRIVER_STATUS.PICKED_UP && (
                  <span>Order was picked up</span>
                )}
                {order?.data?.status === BOOK_A_DRIVER_STATUS.ASSIGNED && (
                  <span>Courier has been assigned</span>
                )}
                {order?.data?.status === BOOK_A_DRIVER_STATUS.DELIVERED && (
                  <span>Order was delivered</span>
                )}
                {order?.data?.status === BOOK_A_DRIVER_STATUS.CANCELLED && (
                  <span>Canceled order</span>
                )}
                {order?.data?.status ===
                  BOOK_A_DRIVER_STATUS.DELIVERY_FAILED && (
                  <span>Delivered failed</span>
                )}
                {order?.data?.status ===
                  BOOK_A_DRIVER_STATUS.DROPOFF_ENROUTE && (
                  <span>Dropoff enroute</span>
                )}
                {order?.data?.status ===
                  BOOK_A_DRIVER_STATUS.PICKUP_ENROUTE && (
                  <span>Pickup enroute</span>
                )}
              </Subtitle1>
              {order?.data?.s2e_meta?.pickup_eta &&
                (order?.data?.status === 'picked-up' ||
                  order?.data?.status === 'delivered') && (
                  <Body0>
                    Order was picked up at <br />{' '}
                    <b>{dateToTimezone(order?.data?.s2e_meta?.pickup_eta)}</b>
                  </Body0>
                )}
            </div>
            <div className='marginBottomXl'>
              <Subtitle className='marginBottomXs2'>Order details</Subtitle>
              <Amount className='marginBottomXs2'>
                <Subtitle1 className='marginRightXs'>
                  <span>
                    {order?.data?.s2e_meta?.manifest_items[0]?.quantity}{' '}
                    {order?.data?.s2e_meta?.manifest_items[0]?.quantity === 1
                      ? 'item'
                      : 'items'}
                  </span>{' '}
                  • <span>${order?.data?.s2e_meta?.value / 100}</span>
                </Subtitle1>
                <Body0>amount</Body0>
              </Amount>
              <Body0 className='marginBottomXs2 Medium'>
                {order?.data?.s2e_meta?.dropoff.notes && (
                  <span>
                    On arrival: {order?.data?.s2e_meta?.dropoff.notes}
                  </span>
                )}
              </Body0>
              {order?.data?.s2e_meta?.pickup_eta && (
                <Body0 className='marginBottomXs2 Medium'>
                  <span className='grey'>Quoted Pickup Time:</span> <br />{' '}
                  {dateToTimezone(order?.data?.s2e_meta?.pickup_eta)}
                </Body0>
              )}
              {order?.data?.s2e_meta?.dropoff_eta && (
                <Body0 className='Medium'>
                  <span className='grey'>Quoted Dropoff Time:</span> <br />{' '}
                  {dateToTimezone(order?.data?.s2e_meta?.dropoff_eta)}
                </Body0>
              )}
              {order?.data?.core_invoice_id && (
                <Body0 className='marginBottomXs2 Medium'>
                  <span className='grey'>Invoice ID:</span> <br />{' '}
                  {order?.data?.core_invoice_id}
                </Body0>
              )}
              {order?.data?.amount_of_drivers &&
                order?.data?.reason === 'LARGE_ORDER' && (
                  <Body0 className='marginBottomXs2 Medium'>
                    <span className='grey'>Number of couriers:</span> <br />{' '}
                    {order?.data?.amount_of_drivers}
                  </Body0>
                )}
            </div>
            <div>
              <Subtitle className='marginBottomXs2'>Customer</Subtitle>
              <Body0 className='Medium'>
                {order?.data?.s2e_meta?.dropoff.name} <br />
                <a
                  href={`tel:${order?.data?.s2e_meta?.dropoff?.phone}`}
                  alt='customer-phone-number'
                  onClick={(e) => e.stopPropagation()}
                >
                  {phoneFormatForView(order?.data?.s2e_meta?.dropoff?.phone)}
                </a>
              </Body0>
            </div>
          </OrderDetailsLside>
          <OrderDetailsRside>
            {(order.data?.status !== 'cancelled' ||
              order?.data?.status !== 'delivered') &&
              order?.data?.reason === 'LARGE_ORDER' &&
              checkIfSauceEmployee() && (
                <OrderDetailsStatusButtons>
                  <ButtonOutline
                    className='ButtonSmall'
                    type='button'
                    onClick={() => changeStatus('cancelled')}
                  >
                    Cancel order
                  </ButtonOutline>
                  <ButtonPrimary
                    className='ButtonSmall'
                    type='button'
                    onClick={() => changeStatus('delivered')}
                  >
                    <span>Resolve order</span>
                  </ButtonPrimary>
                </OrderDetailsStatusButtons>
              )}
            {order?.data?.s2e_order_id && (
              <OrderDetailsActions>
                <ButtonOutline
                  className='Dark ButtonSmall'
                  data-gtm={'trackDeliveryButton'}
                  onClick={() => {
                    if (activeLocation?.TrackingMapApiKey) {
                      window.open(
                        `${process.env.REACT_APP_TRACK_DELIVERY_URL}/${
                          order?.data?.s2e_order_id
                        }?hash=${sha256(
                          `${activeLocation?.TrackingMapApiKey}${order?.data?.s2e_order_id}`
                        ).toString()}&view=r`,
                        '_blank'
                      );
                    }
                  }}
                >
                  Track Delivery
                </ButtonOutline>
              </OrderDetailsActions>
            )}
            <OrderDetailsNotes>
              <Body0 className='marginBottomXs2 Medium'>Internal notes</Body0>
              <FieldTextarea
                className='FieldTextareaLg marginBottomXs'
                placeholder='Write your notes here'
                value={notes}
                onChange={handleNotes}
              />
              <ButtonPrimary
                className='ButtonPrimary'
                style={{maxWidth: '160px'}}
                onClick={saveNotes}
                data-gtm={'saveNotes'}
              >
                <span>Save Notes</span>
              </ButtonPrimary>
            </OrderDetailsNotes>
          </OrderDetailsRside>
        </OrderDetails>
      </DashboardInner>
    </DashboardContent>
  );
}
