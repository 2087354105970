import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _debounce from 'lodash/debounce';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {FormControl} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  DashboardField,
  DashboardFields,
  FieldInput,
  FieldInputDollar
} from 'routes/App/dashboard/styles';
import Subtitle from 'components/typography/Subtitle1';
import SelectWrapper from 'components/select/SelectComponentStyles';
import {
  RC_GENERATE_ORDER_REFUND,
  RC_SET_FIXED_AMOUNT_REASON,
  RC_SET_CUSTOMER_REFUND_AMOUNT,
  RC_SET_LOCATION_REVERSE_AMOUNT,
  RC_CLEAR_CALCULATOR_DATA,
  RC_SET_OTHER_FREE_TEXT
} from 'store/refundsCalculator/action';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import {
  FixedAmountBorder,
  FixedAmountRefundContainer
} from 'routes/App/Managers/RefundsCalculator/styles';
import {FIXED_AMOUNT_REASON} from 'helpers/constants/refundCalculator';
import {twoDecimalsFormatter} from 'helpers/functionUtils/helpFunctions';
import CheckboxS2E from 'components/Checkbox';
import Textarea from 'components/FieldTextarea/style';

const FixedAmount = ({typeValue, setFaultError}) => {
  const dispatch = useDispatch();
  const {
    orderData,
    customerRefundAmount,
    locationReverseAmount,
    fixedAmountReason,
    refundResponsibility
  } = useSelector((state) => state.refundsCalculator);

  const [refundReason, setRefundReason] = useState(0);
  const [refundAmount, setRefundAmount] = useState(null);
  const [refundAmountError, setRefundAmountError] = useState(null);
  const [reverseAmount, setReverseAmount] = useState(null);
  const [reverseAmountError, setReverseAmountError] = useState(null);
  const [checkSameAmount, setCheckSameAmount] = useState(false);
  const [reasonValidateError, setReasonValidateError] = useState(null);
  const [freeText, setFreeText] = useState('');
  const [freeTextError, setFreeTextError] = useState(null);

  const debouncedSubmitChanges = useCallback(
    _debounce(() => {
      if (
        refundResponsibility >= 0 &&
        refundReason &&
        fixedAmountReason &&
        customerRefundAmount &&
        (refundResponsibility === 0 ||
        (refundResponsibility === 1 && orderData.deliveryType === 'Delivery')
          ? locationReverseAmount && Number(locationReverseAmount) !== 0
          : true)
      ) {
        dispatch(RC_GENERATE_ORDER_REFUND(typeValue));
      } else {
        if (!refundReason) {
          setReasonValidateError('Mandatory field');
        }
        if (refundResponsibility === -1) {
          setFaultError('Mandatory field');
        }
      }
    }, 500),
    [
      dispatch,
      refundResponsibility,
      refundReason,
      typeValue,
      setFaultError,
      fixedAmountReason,
      customerRefundAmount,
      locationReverseAmount
    ]
  );

  const handleChange = (event) => {
    setRefundReason(event.target.value);
    setFreeText('');
    setFreeTextError(null);
    dispatch(RC_SET_OTHER_FREE_TEXT(null));
    dispatch(RC_SET_FIXED_AMOUNT_REASON(event.target.value));

    if (event.target.value === 'Other') {
      setFreeTextError('Mandatory field');
    }
    setReasonValidateError(null);
  };

  const handleReverseAmountChange = (value) => {
    setReverseAmount(value);
    if (Number(value) > orderData.locationNetAmount) {
      setReverseAmountError(
        'Requested reverse amount is higher than location transfer'
      );
      dispatch(RC_SET_LOCATION_REVERSE_AMOUNT(null));
    } else {
      dispatch(RC_SET_LOCATION_REVERSE_AMOUNT(Number(value)));
      setReverseAmountError(null);
    }
  };

  const handleFreeTextChange = (e) => {
    const {value} = e.target;
    setFreeText(value);
    if (value?.trim()?.length > 150) {
      setFreeTextError('Max length is 150 characters');
      dispatch(RC_SET_OTHER_FREE_TEXT(''));
    } else if (value?.trim()?.length === 0) {
      setFreeTextError('Mandatory field');
      dispatch(RC_SET_OTHER_FREE_TEXT(value?.trim()));
    } else {
      setFreeTextError(null);
      dispatch(RC_SET_OTHER_FREE_TEXT(value?.trim()));
    }
  };

  useEffect(() => {
    if (
      fixedAmountReason &&
      customerRefundAmount &&
      (refundResponsibility === 0 ||
      (refundResponsibility === 1 && orderData.deliveryType === 'Delivery')
        ? locationReverseAmount && Number(locationReverseAmount) !== 0
        : true)
    ) {
      debouncedSubmitChanges();
    }
  }, [
    fixedAmountReason,
    customerRefundAmount,
    locationReverseAmount,
    refundResponsibility
  ]);

  useEffect(() => {
    if (refundResponsibility) {
      setReverseAmount(null);
      dispatch(RC_SET_LOCATION_REVERSE_AMOUNT(null));
    }
  }, [refundResponsibility]);

  useEffect(() => {
    return () => {
      debouncedSubmitChanges.cancel();
    };
  }, [debouncedSubmitChanges]);

  return (
    <FixedAmountRefundContainer>
      <DashboardFields className='noWrapDesktop AlignCenter spaceBetween'>
        <DashboardField className='wAuto labelFieldFix'>
          <Subtitle>
            Refund reason<span>*</span>
          </Subtitle>
          <ErrorSignWrapper>
            <ErrorSign className='colorRed'>
              {reasonValidateError !== null && reasonValidateError}
            </ErrorSign>
          </ErrorSignWrapper>
        </DashboardField>
        <DashboardField className='wAutoSpace width400'>
          <SelectWrapper className='SelectMd w100'>
            <FormControl className='select-formcontrol'>
              <Select
                value={refundReason}
                name='reason'
                onChange={handleChange}
                className={cn({error: reasonValidateError !== null})}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                {FIXED_AMOUNT_REASON.map((reason) => (
                  <MenuItem value={reason.value}>{reason.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </SelectWrapper>
        </DashboardField>
      </DashboardFields>
      <DashboardFields className='noWrapDesktop'>
        {refundReason === 'Other' && (
          <DashboardField className='w100'>
            <FormControl style={{width: '100%'}}>
              <Textarea
                style={
                  refundReason === 'Other' && freeTextError !== null
                    ? {
                        width: '100%',
                        resize: 'none',
                        border: '1px solid #ba0302'
                      }
                    : {width: '100%', resize: 'none'}
                }
                className={cn('FieldTextareaLg', {
                  error: refundReason === 'Other' && freeTextError !== null
                })}
                name='freeText'
                value={freeText}
                onChange={handleFreeTextChange}
                placeholder='Other reason'
              />
            </FormControl>
            <ErrorSignWrapper>
              <ErrorSign className='colorRed'>
                {freeTextError !== null && freeTextError}
              </ErrorSign>
            </ErrorSignWrapper>
          </DashboardField>
        )}
      </DashboardFields>
      <FixedAmountBorder />
      <DashboardFields className='noWrapDesktop AlignCenter spaceBetween'>
        <DashboardField className='wAuto'>
          <Subtitle>Refund amount</Subtitle>
          <ErrorSignWrapper className='contents'>
            <ErrorSign className='colorRed'>
              {refundAmountError !== null && refundAmountError}
            </ErrorSign>
          </ErrorSignWrapper>
        </DashboardField>
        <DashboardField className='wAutoSpace width140'>
          <FieldInputDollar>
            <FieldInput
              type='text'
              name='refundAmount'
              value={refundAmount}
              onChange={(e) => {
                const value = twoDecimalsFormatter(
                  e.target.value,
                  refundAmount,
                  999999.99,
                  true
                );
                setRefundAmount(value);
                if (Number(value) > orderData.finalPayment) {
                  setRefundAmountError(
                    'Requested refund amount is higher than payment amount'
                  );
                  dispatch(RC_SET_CUSTOMER_REFUND_AMOUNT(null));
                } else {
                  dispatch(RC_SET_CUSTOMER_REFUND_AMOUNT(Number(value)));
                  setRefundAmountError(null);
                }
                if (checkSameAmount) {
                  handleReverseAmountChange(value);
                }
              }}
              className={cn({error: refundAmountError !== null})}
            />
          </FieldInputDollar>
        </DashboardField>
      </DashboardFields>
      {(refundResponsibility === 0 ||
        (refundResponsibility === 1 &&
          orderData.deliveryType === 'Delivery')) && (
        <>
          <DashboardFields>
            <DashboardField className='w100'>
              <CheckboxS2E
                onChange={(e) => {
                  setCheckSameAmount(e.target.checked);
                  if (e.target.checked) {
                    handleReverseAmountChange(refundAmount);
                  }
                }}
                name='checkSameAmount'
                checked={checkSameAmount}
                label='Reverse same amount'
              />
            </DashboardField>
          </DashboardFields>
          <DashboardFields className='noWrapDesktop AlignCenter spaceBetween'>
            <DashboardField className='wAuto'>
              <Subtitle>Reverse amount</Subtitle>
              <ErrorSignWrapper className='contents'>
                <ErrorSign className='colorRed'>
                  {reverseAmountError !== null && reverseAmountError}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
            <DashboardField className='wAutoSpace width140'>
              <FieldInputDollar>
                <FieldInput
                  type='text'
                  name='reverseAmount'
                  value={reverseAmount}
                  onChange={(e) => {
                    const value = twoDecimalsFormatter(
                      e.target.value,
                      reverseAmount,
                      999999.99,
                      true
                    );
                    handleReverseAmountChange(value);
                  }}
                  className={cn({
                    error: reverseAmountError !== null,
                    isDisabled: checkSameAmount
                  })}
                />
              </FieldInputDollar>
            </DashboardField>
          </DashboardFields>
        </>
      )}
    </FixedAmountRefundContainer>
  );
};

FixedAmount.propTypes = {
  typeValue: PropTypes.number.isRequired,
  setFaultError: PropTypes.func
};

FixedAmount.defaultProps = {
  setFaultError: () => {}
};

export default FixedAmount;
