import styled from 'styled-components';

export const ErrorSignWrapper = styled.div`
  display: block;
  position: absolute;
  vertical-align: middle;
  top: ${(props) =>
    props.topPosition
      ? `calc(100% + ${props.topPosition})`
      : 'calc(100% + -12px)'};
  left: ${(props) => props.leftPosition || '12px'};
  z-index: ${(props) => props.zIndex || 'auto'};

  &.maxTwoString {
    overflow: hidden;
    max-width: calc(100% - 24px);
    text-overflow: ellipsis;
    & > div {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &.positionRight {
    right: 20%;
    left: auto;
  }
  &.positionRight3 {
    right: 3%;
    left: auto;
  }
  &.positionRight2 {
    right: 2%;
    left: auto;
  }
  &.centerPosition {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
  &.positionBottom {
    top: calc(100%);
  }
  &.positionBottom3 {
    top: calc(100% + 3px);
  }
  &.left0 {
    left: 0;
  }
  &.positionInitial {
    position: initial;
  }
  &.noWrap {
    & > div {
      white-space: nowrap;

      @media (max-width: 599px) {
        white-space: pre-line;
      }
    }
  }
  &.contents {
    display: contents;
  }
`;

export const ErrorSign = styled.div`
  color: #ba0302;
  font-size: 11px;
  line-height: 1;
  font-weight: normal;
  white-space: pre-line;
  max-width: 100%;
  overflow: hidden;
  min-height: 12px;

  &.smallFont {
    font-size: 10px;
  }

  &.warning {
    color: #fca600;
  }
  &.colorRed {
    color: #ff2d38;
  }

  &.noWrap {
    white-space: nowrap;
  }
`;
