export const FEATURE_RESTRICTION_IDS = {
  ORDERS: 'orders',
  BOOK_A_DRIVER: 'book_a_driver',
  BOOK_A_DRIVER_V2: 'book_a_driver_v2',
  MENU_EDITOR: 'menu_editor',
  STORE_SETTINGS: 'store_settings',
  ANALYTICS: 'analytics',
  DISCOUNTS: 'discounts',
  REPORTS: 'reports',
  UBER_EATS: 'uber_eats',
  VIRTUAL_NUMBERS: 'virtual_number',
  FEEDBACK: 'feedback',
  MY_ACCOUNT: 'my_account',
  TEAM: 'team',
  HELP: 'help',
  REFUNDS: 'refunds',
  SALES_REPORTS: 'sales_reports',
  CUSTOMERS_REPORTS: 'customers_reports',
  REFUNDS_REPORTS: 'refunds_reports',
  EMAIL_MARKETING: 'marketing',
  FLYERS: 'flyers',
  INSIGHTS_GROWTH: 'insights_growth',
  INSIGHTS_PERFORMANCE: 'insights_performance',
  PAYMENT_ADJUSTMENT: 'payment_adjustment',
  MARKETING_INTEGRATIONS: 'marketing_integrations'
};

export const FEATURE_RESTRICTION_PATHS = {
  [FEATURE_RESTRICTION_IDS.ORDERS]: '/orders',
  [FEATURE_RESTRICTION_IDS.BOOK_A_DRIVER]: '/book-a-driver',
  [FEATURE_RESTRICTION_IDS.BOOK_A_DRIVER_V2]: '/book-a-driver',
  [FEATURE_RESTRICTION_IDS.UBER_EATS]: '/ubereats',
  [FEATURE_RESTRICTION_IDS.MENU_EDITOR]: '/menu-editor',
  [FEATURE_RESTRICTION_IDS.STORE_SETTINGS]: '/store-settings',
  [FEATURE_RESTRICTION_IDS.ANALYTICS]: '/analytics',
  [FEATURE_RESTRICTION_IDS.DISCOUNTS]: '/discounts',
  [FEATURE_RESTRICTION_IDS.REPORTS]: '/reports',
  [FEATURE_RESTRICTION_IDS.VIRTUAL_NUMBERS]: '/virtual-number',
  [FEATURE_RESTRICTION_IDS.FEEDBACK]: '/feedback',
  [FEATURE_RESTRICTION_IDS.TEAM]: '/team',
  [FEATURE_RESTRICTION_IDS.EMAIL_MARKETING]: '/marketing',
  [FEATURE_RESTRICTION_IDS.FLYERS]: '/flyers',
  [FEATURE_RESTRICTION_IDS.MY_ACCOUNT]: '/my-account',
  [FEATURE_RESTRICTION_IDS.INSIGHTS_GROWTH]: '/insights/growth',
  [FEATURE_RESTRICTION_IDS.INSIGHTS_PERFORMANCE]: '/insights/performance'
};

export const STRIPE_ACCOUNT_RESTRICTION_NAMES = {
  CONNECTED_STANDARD: 'Connected_Standard',
  CONNECTED_EXPRESS: 'Express'
};

export const stripeAccRestrictedFeatures = ['sales_reports', 'refunds_reports'];

export const getInsightsRestriction = (serviceFeatures) => {
  let result;
  if (serviceFeatures.includes(FEATURE_RESTRICTION_IDS.INSIGHTS_GROWTH)) {
    result = FEATURE_RESTRICTION_IDS.INSIGHTS_GROWTH;
  } else {
    result = FEATURE_RESTRICTION_IDS.INSIGHTS_PERFORMANCE;
  }
  return result;
};

export const getBookADriverRestriction = (serviceFeatures) => {
  let result;
  if (serviceFeatures.includes(FEATURE_RESTRICTION_IDS.BOOK_A_DRIVER_V2)) {
    result = FEATURE_RESTRICTION_IDS.BOOK_A_DRIVER_V2;
  } else {
    result = FEATURE_RESTRICTION_IDS.BOOK_A_DRIVER;
  }

  return result;
};
