import React, {useState} from 'react';
import {FormControl} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import TabPanel from 'components/shared/TabPanel';
import SelectWrapper from 'components/select/SelectComponentStyles';
import {DashboardInner} from 'components/DashboardInner/styles';
import {DashboardField, DashboardFields} from 'routes/App/dashboard/styles';
import Subtitle from 'components/typography/Subtitle1';
import {
  FullRefundContainer,
  FullRefundItem,
  FullRefundItems,
  RCTabs
} from 'routes/App/Managers/RefundsCalculator/styles';
import Body5 from 'components/typography/Body5';
import Subtitle5 from 'components/typography/Subtitle5';
import PartialRefund from 'routes/App/Managers/RefundsCalculator/Components/RCTabs/PartialRefund';
import SubtotalRefund from 'routes/App/Managers/RefundsCalculator/Components/RCTabs/SubtotalRefund';
import {
  RC_CLEAR_CALCULATOR_DATA,
  RC_GENERATE_ORDER_REFUND,
  RC_LOCATION_FAULT_SELECT,
  RC_OPEN_CONFIRMATION_MODAL,
  RC_SET_LOCATION_REVERSE_AMOUNT
} from 'store/refundsCalculator/action';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import {ButtonPrimary} from 'components/buttons';
import ConfirmationRefundModal from 'routes/App/Managers/RefundsCalculator/Components/Modals/ConfirmationRefundModal';
import Loading from 'components/shared/Utils/Loading';
import {REFUND_TYPES} from 'helpers/constants/refundCalculator';
import FixedAmount from 'routes/App/Managers/RefundsCalculator/Components/RCTabs/FixedAmount';

const RCSearch = () => {
  const dispatch = useDispatch();
  const {
    endCustomerRefund,
    restaurantReverse,
    locationFault,
    refundResponsibility,
    orderData,
    partialRefCheckboxes,
    openConfirmationModal,
    percent,
    loading,
    resultLoading,
    priceAdjustmentIds,
    deniedRefundTypes,
    fixedAmountReason,
    customerRefundAmount,
    locationReverseAmount,
    otherFreeText
  } = useSelector((state) => state.refundsCalculator);
  const [valueTabs, setValueTabs] = useState(null);
  const [selectError, setSelectError] = useState(null);

  const handleChangeTabs = (event, newValue) => {
    if (locationFault) {
      setValueTabs(newValue);
      if (valueTabs !== newValue) {
        dispatch(RC_CLEAR_CALCULATOR_DATA());
      }
      if (newValue === 0) {
        dispatch(RC_GENERATE_ORDER_REFUND(newValue));
      }
    } else {
      setSelectError('Mandatory field');
    }
  };

  const handleChangeSelect = (event) => {
    dispatch(RC_LOCATION_FAULT_SELECT(event.target.value));
    if ([0, 1, 2, 3].includes(event.target.value)) {
      setSelectError(null);
      if (valueTabs !== null && valueTabs !== 3) {
        dispatch(RC_SET_LOCATION_REVERSE_AMOUNT(null));
        dispatch(RC_GENERATE_ORDER_REFUND(valueTabs));
      }
    } else {
      setValueTabs(null);
    }
  };

  return (
    <>
      {loading ? (
        <DashboardInner className='RefundsCalculator withoutShadow paddingLg'>
          <Loading />
        </DashboardInner>
      ) : (
        <DashboardInner className='RefundsCalculator withoutShadow paddingLg'>
          <DashboardFields className='noWrapDesktop AlignCenter marginMinus'>
            <DashboardField className='wAuto'>
              <Subtitle>
                Responsible for refund <span>*</span>
              </Subtitle>
            </DashboardField>
            <DashboardField className='wAutoSpace width220'>
              <SelectWrapper className='SelectMd w100'>
                <FormControl className='select-formcontrol'>
                  <Select
                    className={cn({
                      error: selectError
                    })}
                    value={refundResponsibility}
                    name='refundResponsibility'
                    onChange={handleChangeSelect}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    <MenuItem value={-1}>Select</MenuItem>
                    <MenuItem value={0}>Restaurant</MenuItem>
                    {orderData?.orderType !== 'Pickup' && (
                      <MenuItem value={1}>Delivery company</MenuItem>
                    )}
                    <MenuItem value={2}>Sauce</MenuItem>
                  </Select>
                </FormControl>
              </SelectWrapper>
              <ErrorSignWrapper>
                <ErrorSign className='colorRed'>
                  {!!selectError && selectError}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
          </DashboardFields>
          <RCTabs>
            <Tabs value={valueTabs} onChange={handleChangeTabs} elevation={0}>
              <Tab
                className={cn('', {
                  disabled: deniedRefundTypes?.includes(REFUND_TYPES.Full)
                })}
                label='Full Refund'
              />
              <Tab
                className={cn('', {
                  disabled: deniedRefundTypes?.includes(REFUND_TYPES.Partial)
                })}
                label='Partial Refund'
              />
              <Tab
                className={cn('', {
                  disabled: deniedRefundTypes?.includes(REFUND_TYPES.Subtotal)
                })}
                label='Subtotal %'
              />
              <Tab
                className={cn('', {
                  disabled: deniedRefundTypes?.includes(
                    REFUND_TYPES.FixedAmount
                  )
                })}
                label='Fixed Amount'
              />
            </Tabs>
          </RCTabs>
          <TabPanel value={valueTabs} index={0} />
          <TabPanel value={valueTabs} index={1}>
            <PartialRefund
              typeValue={valueTabs}
              setFaultError={setSelectError}
            />
          </TabPanel>
          <TabPanel value={valueTabs} index={2}>
            <SubtotalRefund
              typeValue={valueTabs}
              setFaultError={setSelectError}
            />
          </TabPanel>
          <TabPanel value={valueTabs} index={3}>
            <FixedAmount typeValue={valueTabs} setFaultError={setSelectError} />
          </TabPanel>
          {valueTabs !== null && (
            <>
              <FullRefundContainer>
                <FullRefundItems>
                  <FullRefundItem>
                    <Body5 className='FullRefundTitle'>
                      The end customer refund is
                    </Body5>
                    <Subtitle5 className='FullRefundPrice marginLeftXsPlus'>
                      {resultLoading ? (
                        <Loading className='RefundCalculator' />
                      ) : (
                        <>$ {Number(endCustomerRefund || 0).toFixed(2)}</>
                      )}
                    </Subtitle5>
                  </FullRefundItem>
                  <FullRefundItem>
                    <Body5 className='FullRefundTitle'>
                      The restaurant reverse is{' '}
                    </Body5>
                    <Subtitle5 className='FullRefundPrice marginLeftXsPlus'>
                      {resultLoading ? (
                        <Loading className='RefundCalculator' />
                      ) : (
                        <>$ {Number(restaurantReverse || 0).toFixed(2)}</>
                      )}
                    </Subtitle5>
                  </FullRefundItem>
                </FullRefundItems>
              </FullRefundContainer>
              <DashboardField className='wAuto justifyCenter'>
                <ButtonPrimary
                  className={cn('ButtonMakeRefund', {
                    disabled:
                      (valueTabs === 1 &&
                        !orderData.items.find((el) => el.checked) &&
                        !priceAdjustmentIds?.length &&
                        !partialRefCheckboxes.tip &&
                        !partialRefCheckboxes.deliveryFee) ||
                      (valueTabs === 2 && percent === 0) ||
                      (valueTabs === 3 &&
                        (!endCustomerRefund ||
                          fixedAmountReason === 0 ||
                          !customerRefundAmount ||
                          locationReverseAmount === 0 ||
                          (fixedAmountReason === 'Other' &&
                            !otherFreeText?.length) ||
                          ((refundResponsibility === 0 ||
                            (refundResponsibility === 1 &&
                              orderData.deliveryType === 'Delivery')) &&
                            (!locationReverseAmount ||
                              Number(locationReverseAmount) === 0))))
                  })}
                  onClick={() => dispatch(RC_OPEN_CONFIRMATION_MODAL(true))}
                >
                  <span>
                    {restaurantReverse
                      ? 'Make refund and reverse'
                      : 'Make refund'}
                  </span>
                </ButtonPrimary>
              </DashboardField>
            </>
          )}
          <ConfirmationRefundModal
            openModal={openConfirmationModal}
            refundType={valueTabs}
          />
        </DashboardInner>
      )}
    </>
  );
};

export default RCSearch;
