import {nanoid} from 'nanoid';

const MERCHANT = {
  ID: 'id',
  MERCHANT_NAME: 'MerchantName',
  MERCHANT_ID: 'merchantId',
  LOCATION_ID: 'locationId',
  USER_FULL_NAME: 'ownerFullName',
  USER_EMAIL: 'userEmail',
  MERCHANT_URL: 'reviewedStatus',
  SHORT_URL: 'orderType',
  S2E_URL: 'date',
  ADDRESS: 'address',
  MENU_LINK: 'menuLink',
  IMAGES_LINK: 'imagesLink',
  IT_TEAM_CONTACT: 'teamContact',
  HOST_LINK: 'hostLink',
  HOST_USERNAME: 'hostUsername',
  HOST_PASSWORD: 'hostPassword',
  WEB_CONFIG_ID: 'WixWebOrderingConfigId',
  MENU_CONFIG_ID: 'WixMenuConfigId'
};

export const SALES_PACKAGES = [
  {id: nanoid(), packageName: '$0 package', value: 0},
  {id: nanoid(), packageName: '$1 package', value: 1},
  {id: nanoid(), packageName: '$2 package', value: 2}
];

export const HIDDEN_FEATURE_FLAGS = [
  'orders',
  'store_settings',
  'analytics',
  'feedback',
  'reports',
  'discounts',
  'virtual_number',
  'my_account',
  'team',
  'refunds',
  'help',
  'sales_reports',
  'customers_reports',
  'marketing',
  'one_time_coupon',
  'future_order_reminder',
  'uber_eats',
  'order_with_google_native_ordering',
  'pause_order_with_google_restaurant',
  'refunds_reports',
  'book_a_driver_v2',
  'phone_ordering',
  'reorder',
  'storefront_disable_item_notes',
  'storefront_disable_order_notes',
  'storefront_disable_button_alerts',
  'storefront_disable_ny_courier_fee',
  'storefront_enable_carousel',
  'storefront_enable_stripe_zipcode',
  'storefront_disable_plastic_utensils',
  'storefront_enable_faster_login',
  'storefront_custom_delivery_availability',
  'storefront_enable_sms_agreement',
  'storefront_find_closest_item_v2',
  'storefront_enable_fast_checkout',
  'storefront_disable_como_credits',
  'storefront_custom_size_based_price',
  'storefront_como_sso_redirect',
  'storefront_como_token_sso',
  'storefront_item_image_optimization',
  'storefront_enable_posthog',
  'storefront_default_customer_details',
  'storefront_pickup_as_default',
  'storefront_disable_faq',
  'storefront_enable_1_cent_fix',
  'storefront_expanded_opening_hours',
  'storefront_unsupported_phone_modal'
];

export default MERCHANT;
