import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {NewLineBlock, OrderEta} from '../../List/styles';
import {ORDER} from '../../../../../helpers/constants/orders';
import {FlexContainer, FlexItem} from '../../../../../components/layout';
import TableComponent from '../../../../../components/TableComponent';
import StatusType from '../../List/components/StatusType/styles';
import {phoneFormatForView} from '../../../../../helpers/functionUtils/helpFunctions';
import {groupDigital} from '../../../../../helpers/functionUtils/groupByDigital';

function capitalizeFirstLetter(value) {
  let newStr;
  if (value) {
    newStr = value[0].toUpperCase() + value.slice(1);
    if (
      newStr === 'Delivery-failed' ||
      newStr === 'Dropoff-enroute' ||
      newStr === 'Pickup-enroute'
    ) {
      newStr = newStr.split('-').join(' ');
    }
  } else {
    newStr = '';
  }
  return newStr;
}

function dateToTimezone(date, timezone) {
  let newDate = date;
  if (newDate) {
    newDate = moment(newDate)
      .utc()
      .utcOffset(Number(timezone))
      .format('MMMM D, h:mm A');
  }
  return newDate;
}

const getFieldsConfig = (onOpenDetailsClick, timezone) => [
  {
    title: 'Pickup Time',
    sortable: true,
    onCellClick: onOpenDetailsClick,
    fieldName: 's2e_meta.pickup_eta',
    renderer: (data) => (
      <OrderEta>
        {data?.s2e_meta?.pickup_eta &&
          dateToTimezone(data?.s2e_meta?.pickup_eta, timezone)}
      </OrderEta>
    )
  },
  {
    title: 'Customer',
    onCellClick: onOpenDetailsClick,
    renderer: (data) => (
      <FlexContainer className='TableCenter'>
        <NewLineBlock className='wordWrap'>
          {data?.s2e_meta?.dropoff?.name}
        </NewLineBlock>
        <NewLineBlock>
          <a
            href={`tel:${data?.s2e_meta?.dropoff?.phone}`}
            alt='customer-phone-number'
            onClick={(e) => e.stopPropagation()}
          >
            {phoneFormatForView(data?.s2e_meta?.dropoff?.phone)}
          </a>
        </NewLineBlock>
      </FlexContainer>
    )
  },
  {
    title: 'Customer Address',
    onCellClick: onOpenDetailsClick,
    renderer: (data) => (
      <div>
        {data?.s2e_meta?.dropoff?.number} {data?.s2e_meta?.dropoff?.street}
      </div>
    )
  },
  {
    title: 'Status',
    onCellClick: onOpenDetailsClick,
    fieldName: 'status',
    sortable: true,
    renderer: (data) => {
      return (
        <StatusType className={data?.status}>
          {capitalizeFirstLetter(data?.status)}
        </StatusType>
      );
    }
  },
  {
    title: 'Subtotal ',
    onCellClick: onOpenDetailsClick,
    renderer: (data) => (
      <div>{`$${groupDigital(
        (Number(data?.s2e_meta?.value) / 100).toFixed(2)
      )} ${data[ORDER.PAYMENT_TYPE] || ''}`}</div>
    )
  }
];

const OrdersTable = ({
  openDetails,
  orders,
  loading,
  onSort,
  orderBy,
  orderDirection,
  isNetworkOnline,
  timezone
}) => {
  return (
    <TableComponent
      fields={getFieldsConfig(openDetails, timezone, isNetworkOnline)}
      data={orders}
      loading={loading}
      rowKeyPath='s2e_order_id'
      onSort={onSort}
      orderBy={orderBy}
      orderDirection={orderDirection}
    />
  );
};

OrdersTable.propTypes = {
  openDetails: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  isNetworkOnline: PropTypes.bool.isRequired,
  onSort: PropTypes.func,
  orderBy: PropTypes.string,
  timezone: PropTypes.string,
  orderDirection: PropTypes.string
};

OrdersTable.defaultProps = {
  onSort: () => {},
  orderBy: '',
  orderDirection: '',
  timezone: ''
};

export default OrdersTable;
