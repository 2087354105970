import styled from 'styled-components';
import IconPlusRed from '../../../../assets/icon-plus-red.svg';
import IconMinusRed from '../../../../assets/icon-minus-red.svg';

export const RefundsCalculatorContainer = styled.div`
  .RCSearch {
    .DashboardFields {
      @media (max-width: 720px) {
        display: block;
        .wAuto {
          padding-top: 0;
        }
      }
    }
  }
`;

export const RCHeaderItems = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 -20px;
  @media (max-width: 1023px) {
    margin: 0 -10px;
  }
`;

export const RCHeaderItem = styled.div`
  flex: 1 1 auto;
  padding: 0 20px;
  text-align: center;
  position: relative;
  max-width: 25%;
  @media (max-width: 1023px) {
    padding: 0 10px;
  }
  &::after {
    top: 0;
    right: 0;
    content: '';
    position: absolute;
    width: 1px;
    height: 54px;
    background-color: #dfe2ea;
  }
  &:nth-last-child(1),
  &:nth-last-child(2) {
    &::after {
      display: none;
    }
  }

  &.wAuto {
    flex: 0 0 auto;
    max-width: inherit;
    .ButtonInfoDrop {
      margin-top: 16px;
    }
  }
  .RCHeaderTitle {
    @media (max-width: 1023px) {
      font-size: 18px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  .RCHeaderValue {
    @media (max-width: 1023px) {
      font-size: 15px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
`;

export const RCTabs = styled.div`
  margin: 40px 0 0;
  @media (max-width: 1023px) {
    margin: 25px 0;
  }
  @media (max-width: 599px) {
    margin: 15px 0;
  }

  .MuiTabs-root {
    overflow: inherit;
    margin: 0 -10px;
    @media (max-width: 1023px) {
      margin: 0 -5px;
    }
    @media (max-width: 767px) {
      margin: 0 -4px;
    }
    @media (max-width: 599px) {
      margin: -4px;
    }
    .MuiTabs-scroller {
      overflow: inherit !important;
      display: block;
    }
    .MuiTabs-flexContainer {
      width: 100%;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
  .MuiButtonBase-root {
    display: inline-block;
    flex: 0 0 calc(25% - 20px);
    margin: 0 10px;
    max-width: 100%;
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    color: #ff2d38;
    box-shadow: 0 8px 16px rgba(255, 45, 56, 0);
    border-radius: 30px;
    padding: 17px 26px;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
    text-transform: none;
    transition-property: box-shadow, color;
    transition-duration: 0.3s;
    border: 2px solid #ff2d38;
    opacity: 1;
    @media (max-width: 1023px) {
      flex: 0 0 calc(25% - 10px);
      margin: 0 5px;
      font-size: 17px;
      padding: 15px 20px;
    }
    @media (max-width: 767px) {
      flex: 0 0 calc(50% - 8px);
      margin: 4px;
      font-size: 16px;
      padding: 12px;
    }
    @media (max-width: 599px) {
      flex: 0 0 calc(50% - 8px);
      margin: 4px;
    }
    &:hover {
      box-shadow: 0 8px 16px rgba(255, 45, 56, 0.32);
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }
    &.Mui-selected {
      box-shadow: 0 8px 16px rgba(255, 45, 56, 0.32);
      border-width: 0;
      color: #fff;
      &::after {
        opacity: 1;
      }
    }

    &::after {
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: 30px;
      content: '';
      background: linear-gradient(105.63deg, #ff2d38 39.07%, #f0868c 96.81%);
      z-index: 1;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.3s;
    }
    span {
      display: inline-block;
      position: relative;
      z-index: 3;
      text-decoration: none;
      @media (max-width: 599px) {
        line-height: 1.2;
      }
      & > * {
        display: none;
      }
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
`;

export const FullRefundContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 25px 28px;
  margin-top: 26px;
  @media (max-width: 1023px) {
    margin-top: 15px;
    padding: 20px;
  }
`;

export const FullRefundItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -26px;
  @media (max-width: 1023px) {
    margin: 0 -15px;
  }
`;

export const FullRefundItem = styled.div`
  min-width: 50%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1023px) {
    padding: 0 15px;
  }
  .FullRefundTitle {
    @media (max-width: 1023px) {
      font-size: 18px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  .FullRefundPrice {
    flex: 0 0 auto;
    font-size: 23px;
    @media (max-width: 1023px) {
      font-size: 20px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  &:nth-child(2) {
    position: relative;
    &::after {
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      content: '';
      position: absolute;
      width: 1px;
      height: 54px;
      background-color: #dfe2ea;
      @media (max-width: 1023px) {
        height: 40px;
      }
    }
  }
`;

export const PartialRefundContainer = styled.div`
  max-width: 450px;
  margin-top: 32px;
`;
export const PartialRefundItems = styled.div`
  border-bottom: 2px solid #d5d8de;
  padding: 20px 0;
  &:last-child {
    border-bottom: 0;
  }
  .MuiFormControlLabel-label {
    font-size: 18px;
    word-break: break-all;
  }

  &.PartialRefundItemsBold {
    .MuiFormControlLabel-label {
      font-weight: 700;
      font-size: 18px;
    }
  }
`;
export const PartialRefundItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  &:last-child {
    margin-bottom: 0;
  }
  .MuiFormControl-root {
    max-width: 60%;
    padding-right: 10px;
    flex: 0 0 auto;
  }

  .modifier {
    padding-left: ${(props) => {
      if (props.modifierLevel) {
        return `${20 * (props.modifierLevel - 1)}px`;
      }
      return '20px';
    }};
    .MuiFormControlLabel-label {
      color: #828282;
      // padding-left: 20px;
    }
  }

  .modifierN {
    .MuiFormControlLabel-label {
      color: #828282;
      padding-left: 40px;
    }
  }
`;
export const PartialRefundItemRside = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;

  .priceBlock {
    width: 60px;
    text-align: right;
  }
`;
export const InputCounter = styled.div`
  display: flex;
  align-items: center;
  margin-right: 35px;
`;
export const InputCounterField = styled.input`
  display: inline-block;
  border: none;
  outline: none;
  width: 30px;
  margin: 0 5px;
  height: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  color: #000;
  cursor: default;
  pointer-events: none;
`;
export const InputCounterBtn = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 0.3s;
  &.btnPlus {
    background-image: url(${IconPlusRed});
  }
  &.btnMinus {
    background-image: url(${IconMinusRed});
  }
  &:hover {
    opacity: 0.7;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

export const SubtotalRefundContainer = styled.div`
  margin-top: 32px;
`;

export const FixedAmountRefundContainer = styled.div`
  margin-top: 32px;
  max-width: 700px;
`;

export const FixedAmountBorder = styled.div`
  border-bottom: 2px solid #d5d8de;
  margin: 10px 0;
`;

export const RefundResultContainer = styled.div`
  width: 100%;
  max-width: 700px;
  margin-top: 18px;
  padding-left: 10px;
`;

export const RefundResultItems = styled.div`
  border-bottom: 2px solid #d5d8de;
  padding-left: 20px;
  &:last-child {
    border-bottom: 0;
  }
  &.noBorder {
    border-bottom: 0;
  }
`;

export const RefundResultItem = styled.div`
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
  padding-left: ${(props) => {
    if (props.modifierLevel) {
      return `${20 * (props.modifierLevel - 1)}px`;
    }
    return '0';
  }};

  .list-title {
    word-break: break-all;
    display: list-item;
    list-style-type: disc;
    margin-bottom: 7px;
    width: ${(props) => {
      if (props.modifierLevel) {
        return `${300 - 20 * (props.modifierLevel - 1)}px`;
      }
      return '300px';
    }};

    &.minusWidth {
      width: ${(props) => {
        if (props.modifierLevel) {
          return `${280 - 20 * (props.modifierLevel - 1)}px`;
        }
        return '280px';
      }};
    }
    &::marker {
      color: #ff2d38;
    }
  }

  .modifier {
    padding-left: ${(props) => {
      if (props.modifierLevel) {
        return `${20 * (props.modifierLevel - 1)}px`;
      }
      return '20px';
    }};
  }
`;

export const RefundResultItemRside = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  padding-left: 10px;

  .rightBlock {
    // width: 60px;
    text-align: left;
  }
`;

export const RCInfoLink = styled.a`
  color: #ff2d38;
`;
