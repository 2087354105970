import React from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import {
  RefundResultContainer,
  RefundResultItem,
  RefundResultItemRside,
  RefundResultItems
} from 'routes/App/Managers/RefundsCalculator/styles';
import {DashboardFields} from 'routes/App/dashboard/styles';
import {DashboardInner} from 'components/DashboardInner/styles';
import Body6 from 'components/typography/Body6';
import Subtitle5 from 'components/typography/Subtitle5';
import {
  REFUND_FAULT_TITLE,
  REFUND_SOURCE
} from 'helpers/constants/refundCalculator';

const getPrice = (item) => {
  return (item.quantity * item.price).toFixed(2);
};

const getRefundStatusString = (refund) => {
  let string;
  if (refund.status === 'Failed') {
    string = 'Failed';
  } else if (refund.status === 'Succeeded') {
    if (refund.type === 'Partial') {
      string = 'Partially refunded';
    } else if (refund.type === 'Subtotal') {
      string = 'Partially refunded';
    } else if (refund.type === 'Fixed') {
      string = 'Partially refunded';
    } else if (refund.type === 'Full') {
      string = 'Fully refunded';
    }
  } else {
    string = refund.status;
  }
  return string;
};

const RCResult = () => {
  const {orderData, refunds} = useSelector((state) => state.refundsCalculator);

  return (
    <>
      <DashboardInner className='RefundsCalculator withoutShadow paddingLg'>
        <Subtitle5>Refund details</Subtitle5>
        <DashboardFields className='noWrapDesktop AlignCenter marginMinus'>
          <RefundResultContainer>
            <Body6 className='fw700'>Order info</Body6>
            <RefundResultItems className='noBorder'>
              {orderData.items.map((orderItem) => (
                <RefundResultItem
                  modifierLevel={orderItem.path.length}
                  className={cn({
                    modifier: orderItem.path.length > 1
                  })}
                >
                  <Body6 className='list-title fw400'>
                    {orderItem.quantity > 1 && `${orderItem.quantity}X `}
                    {orderItem.name}
                  </Body6>
                  <RefundResultItemRside>
                    <Body6 className='rightBlock fw400'>
                      ${getPrice(orderItem)}
                    </Body6>
                  </RefundResultItemRside>
                </RefundResultItem>
              ))}
            </RefundResultItems>
            {orderData?.priceAdjustments?.length > 0 && (
              <RefundResultItems className='noBorder'>
                {orderData.priceAdjustments.map((adjust) => (
                  <RefundResultItem>
                    <Body6 className='list-title fw400'>
                      Adjustment for {adjust?.reason}
                    </Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        ${(adjust.totalAmount || 0).toFixed(2)}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                ))}
              </RefundResultItems>
            )}
            <RefundResultItems>
              <RefundResultItem>
                <Body6 className='list-title fw400'>Tip</Body6>
                <RefundResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    ${(orderData.tip || 0).toFixed(2)}
                  </Body6>
                </RefundResultItemRside>
              </RefundResultItem>
              <RefundResultItem>
                <Body6 className='list-title fw400'>Delivery fee</Body6>
                <RefundResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    ${(orderData.deliveryFee || 0).toFixed(2)}
                  </Body6>
                </RefundResultItemRside>
              </RefundResultItem>
            </RefundResultItems>
          </RefundResultContainer>
          {refunds.map((item) => (
            <>
              <RefundResultContainer>
                <Body6 className='fw700'>Refund info</Body6>
                <RefundResultItems>
                  <RefundResultItem>
                    <Body6 className='list-title fw400'>Refund date</Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {moment(item.createdAt)
                          .utc()
                          .format('MM.DD.YY h:mm a') || 'N/A'}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                  <RefundResultItem>
                    <Body6 className='list-title fw400'>Refund source</Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {REFUND_SOURCE[item.source] || 'N/A'}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                  <RefundResultItem>
                    <Body6 className='list-title fw400'>
                      Responsible party
                    </Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {item.initiator || 'N/A'}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                  <RefundResultItem>
                    <Body6 className='list-title fw400'>Status</Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {getRefundStatusString(item) || 'N/A'}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                  <RefundResultItem>
                    <Body6 className='list-title fw400'>Refund fault</Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {REFUND_FAULT_TITLE[item.responsibility] || 'N/A'}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                  <RefundResultItem>
                    <Body6 className='list-title fw400'>Refund type</Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {item.type === 'Fixed'
                          ? 'Fixed amount refund'
                          : item.type || 'N/A'}
                        {item.type === 'Subtotal' ? ' %' : ''}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                  {item?.reason && (
                    <RefundResultItem>
                      <Body6 className='list-title fw400'>Refund reason</Body6>
                      <RefundResultItemRside>
                        <Body6 className='rightBlock fw400'>
                          {item.reason}
                        </Body6>
                      </RefundResultItemRside>
                    </RefundResultItem>
                  )}

                  {item.type === 'Partial' && (
                    <>
                      <RefundResultItems className='noBorder'>
                        {item?.refundedItems?.map((orderItem) => (
                          <RefundResultItem
                            modifierLevel={orderItem.path?.length}
                            className={cn({
                              modifier: orderItem.path?.length > 1
                            })}
                          >
                            <Body6 className='list-title minusWidth fw400'>
                              {orderItem.quantity > 1 &&
                                `${orderItem.quantity}X `}
                              {orderItem.name}
                            </Body6>
                            <RefundResultItemRside>
                              <Body6 className='rightBlock fw400'>
                                ${getPrice(orderItem)}
                              </Body6>
                            </RefundResultItemRside>
                          </RefundResultItem>
                        ))}
                      </RefundResultItems>
                      {item.refundedPriceAdjustments?.length > 0 && (
                        <RefundResultItems className='noBorder'>
                          {item?.refundedPriceAdjustments.map((adjust) => (
                            <RefundResultItem>
                              <Body6 className='list-title minusWidth fw400'>
                                Order adjustment for {adjust?.reason}
                              </Body6>
                              <RefundResultItemRside>
                                <Body6 className='rightBlock fw400'>
                                  ${(adjust?.totalAmount || 0).toFixed(2)}
                                </Body6>
                              </RefundResultItemRside>
                            </RefundResultItem>
                          ))}
                        </RefundResultItems>
                      )}
                      <RefundResultItems className='noBorder'>
                        {!!item.tip && (
                          <RefundResultItem>
                            <Body6 className='list-title minusWidth fw400'>
                              Tip
                            </Body6>
                            <RefundResultItemRside>
                              <Body6 className='rightBlock fw400'>
                                ${(item.tip || 0).toFixed(2)}
                              </Body6>
                            </RefundResultItemRside>
                          </RefundResultItem>
                        )}
                        {!!item.deliveryFee && (
                          <RefundResultItem>
                            <Body6 className='list-title minusWidth fw400'>
                              Delivery fee
                            </Body6>
                            <RefundResultItemRside>
                              <Body6 className='rightBlock fw400'>
                                ${(item.deliveryFee || 0).toFixed(2)}
                              </Body6>
                            </RefundResultItemRside>
                          </RefundResultItem>
                        )}
                      </RefundResultItems>
                    </>
                  )}
                  {!!item.percent && (
                    <RefundResultItem>
                      <Body6 className='list-title fw400'>Refunded %</Body6>
                      <RefundResultItemRside>
                        <Body6 className='rightBlock fw400'>
                          ${item.percent || 'N/A'}
                        </Body6>
                      </RefundResultItemRside>
                    </RefundResultItem>
                  )}
                  <RefundResultItem>
                    <Body6 className='list-title fw400'>
                      User refunded sum
                    </Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        ${item.refundedTotal.toFixed(2) || 'N/A'}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                  <RefundResultItem>
                    <Body6 className='list-title fw400'>
                      Restaurant reversed sum
                    </Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        ${item.locationReversedTotal?.toFixed(2) || 'N/A'}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                  <RefundResultItem>
                    <Body6 className='list-title fw400'>
                      Reversed franchising commission
                    </Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        ${item.franchisorReversedTotal?.toFixed(2) || 'N/A'}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                  <RefundResultItem>
                    <Body6 className='list-title fw400'>Refund PI</Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {item.paymentId || 'N/A'}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                  {item.refundedPriceAdjustments?.length > 0 && (
                    <>
                      {item.refundedPriceAdjustments.map((adjust) => (
                        <RefundResultItem>
                          <Body6 className='list-title fw400'>
                            Adjustment PI for {adjust?.reason}
                          </Body6>
                          <RefundResultItemRside>
                            <Body6 className='rightBlock fw400'>
                              {adjust?.paymentIntentId || 'N/A'}
                            </Body6>
                          </RefundResultItemRside>
                        </RefundResultItem>
                      ))}
                    </>
                  )}

                  <RefundResultItem>
                    <Body6 className='list-title fw400'>Invoice ID</Body6>
                    <RefundResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {orderData.invoiceId || 'N/A'}
                      </Body6>
                    </RefundResultItemRside>
                  </RefundResultItem>
                </RefundResultItems>
              </RefundResultContainer>
            </>
          ))}
        </DashboardFields>
      </DashboardInner>
    </>
  );
};

export default RCResult;
