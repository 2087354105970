import React from 'react';
import T from 'prop-types';
import moment from 'moment/moment';
import Dialog from '@material-ui/core/Dialog';
import DialogFields from 'components/DialogFields/styles';
import DialogField from 'components/DialogField/styles';
import DialogClose from 'components/DialogClose/styles';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import DialogBody from 'components/DialogBody/styles';
import {DialogAction} from 'routes/App/dashboard/styles';
import {ButtonOutline} from 'components/buttons';
import {RCInfoLink} from 'routes/App/Managers/RefundsCalculator/styles';

export default function AdditionInformationModal({
  openAdditionInfoModal,
  setOpenAdditionInfoModal,
  orderData
}) {
  const orderDate = moment(orderData.orderDate).format('MM.DD.YY h:mm a');
  const pickupDate = moment(orderData.pickupDate).format('MM.DD.YY h:mm a');
  let dropOffDelayTime =
    parseInt(orderData.dropOffDelay / 60, 10) || orderData.dropOffDelay;

  if (orderData.deliveryStatus === 'Cancelled') {
    dropOffDelayTime = 'N/A';
  } else if (Number.isInteger(dropOffDelayTime)) {
    dropOffDelayTime =
      orderData.dropOffDelay > 0
        ? `+${dropOffDelayTime} min`
        : `${dropOffDelayTime} min`;
  }

  const formatSum = (sum) => {
    return `$${Number(sum).toFixed(2)}`;
  };

  return (
    <Dialog
      open={openAdditionInfoModal}
      onClose={() => setOpenAdditionInfoModal(false)}
      scroll='body'
    >
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={() => setOpenAdditionInfoModal(false)} />
        <DialogTitle className='marginBottomLg'>
          Additional Information
        </DialogTitle>
        <DialogBody className='wAuto'>
          <DialogFields className='column'>
            <DialogField className='bold smallPadding mTop20'>
              <span>Customer info</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Customer Name</span>
              <span>{orderData.customerName}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Customer Email</span>
              <span>{orderData.customerEmail}</span>
            </DialogField>
            <DialogField className='bold smallPadding mTop20'>
              <span>Order Details</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Placed on:</span>
              <span>{orderDate}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Pickup date:</span>
              <span>{pickupDate}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Order Type:</span>
              <span>{orderData.orderType}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Order notes:</span>
              <span>{orderData.orderNotes}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Order status on dashboard:</span>
              <span>{orderData.orderStatus}</span>
            </DialogField>
            <DialogField className='bold smallPadding mTop20'>
              <span>Delivery Details</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Delivery company:</span>
              <span>{orderData.deliveryCompany}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Delivery ID Sauce:</span>
              <span>{orderData.deliveryId}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Delivery company delivery ID:</span>
              <span>{orderData.deliveryCompanyId}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Uber ID:</span>
              <span>{orderData.uberId}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Delivery Status:</span>
              <span>{orderData.deliveryStatus}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Dispatch cancellation reason:</span>
              <span>{orderData.statusReason}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Delivery address:</span>
              <span>{orderData.deliveryAddress}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Delivery instructions:</span>
              <span>{orderData.deliveryInstructions}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Dropoff delay time:</span>
              <span>{dropOffDelayTime}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Tracking link:</span>
              {orderData.trackingLink ? (
                <RCInfoLink
                  href={orderData.trackingLink}
                  rel='noreferrer'
                  target='_blank'
                >
                  Click to open
                </RCInfoLink>
              ) : (
                <span>N/A</span>
              )}
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Proof of delivery:</span>
              {orderData.proofOfDelivery ? (
                <RCInfoLink
                  href={orderData.proofOfDelivery}
                  rel='noreferrer'
                  target='_blank'
                >
                  Click to open
                </RCInfoLink>
              ) : (
                <span>N/A</span>
              )}
            </DialogField>
            <DialogField className='bold smallPadding mTop20'>
              <span>Payment Details</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>
                {orderData.finalMarkup ? 'Subtotal (with MM):' : 'Subtotal:'}
              </span>
              <span>{formatSum(orderData.subtotal)}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Discount amount:</span>
              <span>{formatSum(orderData.discount)}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Taxes & fees:</span>
              <span>
                {formatSum(
                  Number(orderData.tax) + Number(orderData.serviceFee)
                )}
              </span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Delivery fee:</span>
              <span>{formatSum(orderData.deliveryFee)}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Tip:</span>
              <span>{formatSum(orderData.tip)}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Final Payment:</span>
              <span>{formatSum(orderData.finalPayment)}</span>
            </DialogField>
            <DialogField className='RCModal smallPadding'>
              <span>Payment Info:</span>
              <span>{orderData.paymentInfo}</span>
            </DialogField>
            {orderData?.priceAdjustments?.length > 0 && (
              <>
                {orderData?.priceAdjustments.map((adjust) => (
                  <>
                    <DialogField className='bold smallPadding mTop20'>
                      <span>Adjustment</span>
                    </DialogField>
                    <DialogField className='RCModal smallPadding'>
                      <span>Adjustment date:</span>
                      <span>
                        {moment(adjust?.createdAt)
                          .utc()
                          .format('MM.DD.YY hh:mm A')}
                      </span>
                    </DialogField>
                    <DialogField className='RCModal smallPadding'>
                      <span>Adjustment reason:</span>
                      <span>{adjust?.reason}</span>
                    </DialogField>
                    <DialogField className='RCModal smallPadding'>
                      <span>Requested adjustment:</span>
                      <span>{formatSum(adjust?.sourceAmount)}</span>
                    </DialogField>
                    <DialogField className='RCModal smallPadding'>
                      <span>Tax:</span>
                      <span>{formatSum(adjust?.totalTaxAmount)}</span>
                    </DialogField>
                    <DialogField className='RCModal smallPadding'>
                      <span>Total adjustment:</span>
                      <span>{formatSum(adjust?.totalAmount)}</span>
                    </DialogField>
                    <DialogField className='RCModal smallPadding'>
                      <span>Payment intent:</span>
                      <span>{adjust?.paymentIntentId}</span>
                    </DialogField>
                  </>
                ))}
              </>
            )}
          </DialogFields>
          <DialogAction>
            <ButtonOutline
              className='ButtonMin3'
              onClick={() => setOpenAdditionInfoModal(false)}
            >
              Close
            </ButtonOutline>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}

AdditionInformationModal.propTypes = {
  setOpenAdditionInfoModal: T.func.isRequired,
  openAdditionInfoModal: T.bool.isRequired,
  orderData: T.object.isRequired
};
