import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import Headline from 'components/Headline/styles';
import ImgFlyers from 'assets/img-flyers.png';
import {DashboardHeaderActions} from 'routes/App/BookADriver/List/styles';
import {DashboardContent} from 'components/DashboardContent/styles';
import {DashboardHeader} from 'components/DashboardHeader/styles';
import {ButtonPrimary} from 'components/buttons';
import {
  FlyersContainer,
  FlyersContainerImage,
  FlyersContainerText,
  FlyersSubtitle
} from 'routes/App/Flyers/styles';
import {TOGGLE_REQUEST_MODAL} from 'store/flyers/action';
import RequestFlyersModal from 'routes/App/Flyers/Modals/requestFlyersModal';

const Flyers = () => {
  const dispatch = useDispatch();

  const {loading, isOpenRequestModal} = useSelector(
    (state) => state.flyersReducer
  );

  return (
    <DashboardContent>
      <DashboardHeader>
        <Headline>Flyers: Your Tool to Boost Direct Orders</Headline>
        <DashboardHeaderActions>
          <ButtonPrimary
            className={cn('ButtonInsightsPerformanceSettings', {
              disabled: loading
            })}
            onClick={() => dispatch(TOGGLE_REQUEST_MODAL(true))}
          >
            <span>Request Flyers</span>
          </ButtonPrimary>
        </DashboardHeaderActions>
      </DashboardHeader>
      <FlyersSubtitle>
        According to Sauce Statistics, Flyers in the Bag Convert Customers 40%
        Better than other Channels!
        <br />
        Stop Paying Commissions – Drive Customers Directly to Your Website with
        Flyers and Stop Paying Costly Fees.
      </FlyersSubtitle>
      <FlyersContainer>
        <FlyersContainerText>
          <div className='title'>1. What?</div>
          <div>
            Show your customers there’s a better way to order directly from your
            restaurant. Each third-party delivery order eats into your profits
            with commissions – claim the revenue that should be yours by
            including flyers in every delivery bag to guide customers to your
            website.
          </div>
          <div className='title'>2. How?</div>
          <div>
            <ul>
              <li>
                Include flyers in all third-party delivery or pickup orders.
              </li>
              <li>Hand them out to walk-in customers.</li>
              <li>
                Keep flyers at the counter for anyone to grab – every
                interaction counts!
              </li>
            </ul>
            Pro Tip: Entice your customers to order direct with a special
            discount
          </div>
          <div className='title'>3. Why?</div>
          <div>
            We’re sure that each order placed through Third Party delivery is
            welcome, but it can cost you in fees. By encouraging customers to
            order directly from your site, you can:
            <ul>
              <li>Save thousands of dollars annually.</li>
              <li>Strengthen connections with your loyal customers.</li>
              <li>Take control of your profit margins and business growth.</li>
            </ul>
          </div>
        </FlyersContainerText>
        <FlyersContainerImage src={ImgFlyers} alt='' />
      </FlyersContainer>
      <FlyersContainer>
        <FlyersContainerText className='w100'>
          <div className='title'>See How Much You Could Save!</div>
          <div>
            Discover the true cost of third-party orders and see your savings in
            action. Check out the{' '}
            <a
              href='https://home.getsauce.com/fooddeliverychargecalculator'
              target='_blank'
              rel='noreferrer'
            >
              Savings Calculator
            </a>{' '}
            to learn how flyers can transform your bottom line.
          </div>
          <div className='title sm'>Request Flyers Now!</div>
          <div>
            Don’t wait – start maximizing your profits today. Let us help you
            make the switch.
          </div>
        </FlyersContainerText>
      </FlyersContainer>
      {isOpenRequestModal && (
        <RequestFlyersModal
          openModal={isOpenRequestModal}
          closeModalAction={TOGGLE_REQUEST_MODAL}
        />
      )}
    </DashboardContent>
  );
};

export default Flyers;
